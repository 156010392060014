import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

import { config } from '@/config'
import customDomainRoutes from '@/router/customDomain'
import representDomainRoutes from '@/router/representDomain'
import { commonStaticPagesRoutes, representStaticPagesRoutes } from '@/router/staticPages'
import AnalyticsService from '@/services/AnalyticsService'

import store from './store'

Vue.use(Router)

let routes: RouteConfig[] = []

if (config.custom_domain) {
  /* Custom domain (e.g. coryxkenshin.com) */
  routes = [...commonStaticPagesRoutes, ...customDomainRoutes]
} else {
  /* represent.com */
  routes = [...representStaticPagesRoutes, ...commonStaticPagesRoutes, ...representDomainRoutes]
}

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some((m) => m.meta.disableScroll)) return

    if (savedPosition) return savedPosition
    return to.path === from.path ? undefined : { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/cart',
      component: () => import('@/views/base/PageWrapper.vue'),
      redirect: { name: 'cart' },
      props: { fullWidth: true },
      children: [
        {
          meta: { slug: config.slug },
          path: '',
          component: () => import('@/views/shopping_cart/ShoppingCart.vue'),
          name: 'cart',
          props: true
        }
      ]
    },
    {
      meta: { slug: config.slug },
      path: '/checkout',
      component: () => import('@/views/checkout/Checkout.vue'),
      name: 'checkout',
      redirect: { name: 'checkout.shipping' },
      props: (route) => ({
        abandondCartId: route.query.cartId,
        abandondCartToken: route.query.cartToken
      }),
      children: [
        {
          path: 'shipping',
          name: 'checkout.shipping',
          component: () => import('@/views/checkout/CheckoutShipping.vue'),
          beforeEnter(to, _from, next) {
            if (!store.getters['cart/items'].length && !to.query.cartId && !to.query.cartToken) {
              next({ name: 'cart' })
              return
            }

            next()
          }
        },
        {
          path: 'payment',
          name: 'checkout.payment',
          component: () => import('@/views/checkout/CheckoutPayment.vue'),
          beforeEnter(_to, _from, next) {
            if (!store.getters['cart/isShippingValidated']) {
              next({ name: 'checkout.shipping' })
              return
            }

            next()
          }
        }
      ]
    },
    {
      path: '/order/returns-form',
      component: () => import('@/views/base/PageWrapper.vue'),
      redirect: { name: 'returnsForm' },
      children: [
        {
          meta: { slug: config.slug },
          path: '',
          component: () => import('@/views/order/ReturnsForm.vue'),
          name: 'returnsForm',
          props: true
        }
      ]
    },
    {
      path: '/order/:orderId/return-form',
      component: () => import('@/views/base/PageWrapper.vue'),
      redirect: { name: 'returnToSenderForm' },
      children: [
        {
          meta: { slug: config.slug },
          path: '',
          props: (route) => ({
            orderId: Number(route.params.orderId)
          }),
          component: () => import('@/views/order/RtsForm.vue'),
          name: 'returnToSenderForm'
        }
      ]
    },
    {
      path: '/order/:orderId/:orderKey/:hashId/:orderSlug?',
      component: () => import('@/views/base/PageWrapper.vue'),
      redirect: { name: 'storeOrder' },
      children: [
        {
          meta: { slug: config.slug },
          path: '',
          props: (route) => ({
            orderId: Number(route.params.orderId),
            orderKey: route.params.orderKey,
            hashId: Number(route.params.hashId),
            notification: route.params.notification
          }),
          component: () => import('@/views/order/Order.vue'),
          name: 'storeOrder'
        }
      ]
    },
    {
      path: '/:lang?/discount/:discountCode',
      component: () => import('@/views/base/PageWrapper.vue'),
      redirect: { name: 'discount' },
      children: [
        {
          meta: { slug: config.slug },
          path: '',
          component: () => import('@/views/base/Discount.vue'),
          name: 'discount'
        }
      ]
    },
    {
      path: '/theme',
      component: () => import('@/views/base/Theme.vue'),
      name: 'theme'
    },
    {
      path: '/not-found',
      component: () => import('@/views/base/PageWrapper.vue'),
      redirect: { name: 'notFound' },
      children: [
        {
          meta: { slug: config.slug },
          path: '',
          component: () => import('@/views/base/NotFound.vue'),
          name: 'notFound',
          props: true
        }
      ]
    },

    ...routes,

    {
      path: '*',
      redirect: { name: 'notFound' }
    }
  ]
})

const routeAllowlist = [/\/(order|apis|admin|dashboard|sign-in)/]

// Redirect to config.cameo_url if route doesn't match any of the routeAllowlist patterns
router.beforeEach((to, _from, next) => {
  const shouldRedirect = !routeAllowlist.some((pattern) => pattern.test(to.path))

  // if 'amaze' query param is present, don't redirect
  const hasAmazeParam = to.query.amaze === '1'

  if (shouldRedirect && !hasAmazeParam) {
    window.location.href = config.cameo_url
  } else {
    next()
  }
})

router.beforeEach((to, _from, next) => {
  // Watch `review_token` in route query
  if (to.query.review_token) {
    store.dispatch('main/setReviewToken', { reviewToken: to.query.review_token, slug: to.params?.slug || config.slug })
  }

  // Watch promotion `code` in route query
  if (to.query.code) {
    store.dispatch('main/setPromotionCode', to.query.code)
  }

  next()

  // Reset data layer
  AnalyticsService.resetDataLayer()
})

router.afterEach((to) => {
  // Track page visit
  setTimeout(() => {
    AnalyticsService.trackPageView(to.fullPath)
  }, 600)
})

export default router
