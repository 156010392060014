import { RouteConfig } from 'vue-router'

import { config } from '@/config'
import PageWrapper from '@/views/base/PageWrapper.vue'

const defaultRoutes: RouteConfig[] = [
  {
    path: '/',
    meta: {
      slug: config.slug
    },
    component: PageWrapper,
    props: {
      loadStore: true,
      fullWidth: true
    },
    redirect: {
      name: 'store'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/StorePage.vue'),
        name: 'store',
        meta: {
          hasVisibleNavigation: true,
          allowsTransparentHeader: true,
          analytics: {
            pageviewTemplate(route) {
              return {
                page: '/store/' + config.slug + route.path,
                title: document.title,
                location: window.location.href
              }
            }
          }
        },
        props: (route) => ({
          pageSlug: route.params.pageSlug
        })
      },
      {
        path: 'pages/:pageSlug?',
        component: () => import('@/views/StorePage.vue'),
        name: 'pages',
        meta: {
          hasVisibleNavigation: true,
          allowsTransparentHeader: true,
          analytics: {
            pageviewTemplate(route) {
              return {
                page: '/store/' + config.slug + route.path,
                title: document.title,
                location: window.location.href
              }
            }
          }
        },
        props: (route) => ({
          pageSlug: route.params.pageSlug
        }),
        beforeEnter: (to, _from, next) => {
          to.params.pageSlug ? next() : next({ name: 'store' })
        }
      }
    ]
  },

  {
    path: '/:productSlug/:longSlug?',
    meta: {
      slug: config.slug,
      hasVisibleNavigation: true
    },
    component: PageWrapper,
    props: {
      loadStore: true,
      fullWidth: false
    },
    redirect: {
      name: 'product'
    },

    children: [
      {
        path: '',
        component: () => import('@/views/product_page/Product.vue'),
        name: 'product',
        props: (route) => ({
          productSlug: route.params.productSlug,
          longSlug: route.params.longSlug,
          variantId: Number(route.query.variant) || undefined
        }),
        meta: {
          analytics: {
            pageviewTemplate(route) {
              return {
                page: route.path,
                title: document.title,
                location: window.location.href
              }
            }
          }
        }
      }
    ]
  }
]

const customDomainRoutes = [...(config.custom_store ? [] : defaultRoutes)]

export default customDomainRoutes
