<template>
  <div class="_product_items_by_ship_date">
    <div v-for="(itemsByDate, date) in groupByDate(items)" :key="date">
      <div v-for="(itemsByShipment, shipment) in groupByShipments(itemsByDate)" :key="shipment" class="mt-4 mb-2">
        <div class="mb-3 flex items-center">
          <div
            class="text-sm font-semibold uppercase"
            :style="{
              'color': $color('productItemsByShipDate.color.dark')
            }"
          >
            {{ $t('js.order_items_list.shipping_by') }}
            {{ date | formatDate }}
          </div>

          <TrackingNumbers
            v-if="showTracking(itemsByShipment)"
            class="ml-auto"
            :shipment="lastShipment(itemsByShipment[0].shipments)"
          />
        </div>

        <div v-for="publicNote in publicNotes(itemsByShipment)" :key="publicNote">
          <div class="_public_note">
            {{ publicNote }}
          </div>
        </div>

        <ProductItem
          v-for="(item, index) in itemsByShipment"
          :key="index"
          class="mt-2 border-b pb-4"
          :editable-quantity="editableQuantity"
          :editable-size="editableSize"
          :editable-variant="editableVariant"
          :item="item"
          :style="{
            'border-color': $color('productItemsByShipDate.border.lighter')
          }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import groupBy from 'lodash/groupBy'
import last from 'lodash/last'
import uniq from 'lodash/uniq'
import Vue from 'vue'

import TrackingNumbers from '@/components/order/TrackingNumbers.vue'
import ProductItem from '@/components/ProductItem.vue'
import { formatDate } from '@/utils/formatDate'

export default Vue.extend({
  components: {
    TrackingNumbers,
    ProductItem
  },

  filters: {
    formatDate
  },

  props: {
    items: {
      type: Array,
      required: true
    },

    editableQuantity: {
      type: Boolean,
      default: false
    },

    editableSize: {
      type: Boolean,
      default: false
    },

    editableVariant: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    showTracking(items) {
      return this.hasShipments(items) && this.hasTracking(items)
    },

    hasShipments(items) {
      return items.some((item) => last(item.shipments))
    },

    hasTracking(items) {
      const hasTrackingCode = items.some((item) => last(item.shipments as any[]).single_tracking_code)
      // Don't show tracking code when includes message (tracking not available)
      const validTrackingMessage = !this.lastShipment(items[0].shipments).single_tracking_code.includes(
        '(tracking not available)'
      )

      return hasTrackingCode && validTrackingMessage
    },

    lastShipment(shipments) {
      return last(shipments)
    },

    groupByDate(items) {
      return groupBy(items, 'ships_by')
    },

    // Group by shipment id - if item has one, otherwise send to group without shipments
    groupByShipments(items) {
      return groupBy(items, (item) => last(item.shipments as any[])?.id)
    },

    // Return a unique array of public notes for items (in a shipment)
    publicNotes(items) {
      return uniq(
        items
          .map((item) => item.public_notes)
          .flat()
          .filter(Boolean)
      )
    }
  }
})
</script>

<style lang="postcss" scoped>
._public_note {
  @apply ml-4 my-2 py-4 text-center text-red-800 text-sm italic font-semibold bg-red-100 border border-red-200 border-transparent rounded;
}
</style>
