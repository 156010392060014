<template>
  <div>
    <div v-for="message in messages" :key="message.id">
      <SitewideMessage :level="message.level" :text="message.text" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import SitewideMessage from '@/components/SitewideMessage.vue'
import DataService from '@/services/DataService'

export default Vue.extend({
  components: { SitewideMessage },

  data() {
    return {
      messages: []
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.messages = await DataService.sitewideMessage()
    }
  }
})
</script>
