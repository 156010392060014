<template>
  <RSelect
    option-disabled-key="disabled"
    option-label-key="label"
    option-value-key="value"
    :options="sizeOptions"
    :placeholder="`${$t('views.orders.upselling_promotion.select_size')}…`"
    :value="value.code"
    @input="update($event)"
  />
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue'

import RSelect from '@/components/ui/RSelect.vue'
import StockService from '@/services/StockService'

const props = defineProps<{
  sizes: any[]
  stock: any
  value: any
  items: any[]
}>()

const app = getCurrentInstance()

const emit = defineEmits<{
  (e: 'input', value: any)
}>()

const sizeOptions = computed(() => {
  return props.sizes.map((size) => {
    const disabled = !StockService.sizeAvailable(size.code, props.stock, props.items)

    return {
      value: size.code,
      label: size.code + (disabled ? ` (${app.proxy.$t('js.product.out_of_stock')})` : ''),
      disabled
    }
  })
})

const update = (value: string) => {
  emit(
    'input',
    props.sizes.find((size) => size.code === value)
  )
}
</script>
