<template>
  <div class="SlidebarStack fixed z-40">
    <TransitionFadeIn>
      <div v-if="slidebars.length" class="fixed inset-0 z-1 bg-black/30" />
    </TransitionFadeIn>

    <TransitionGroupSlideFromBottom group>
      <div
        v-for="slidebar in slidebars"
        :key="slidebar.id"
        class="fixed inset-0 z-2 flex flex-col items-stretch"
        @click="closeByBackdropClick($event, slidebar)"
      >
        <component
          v-bind="slidebar.props"
          :is="slidebar.component"
          :ref="`slidebar_${slidebar.id}`"
          class="SlidebarStack__slidebar mt-auto max-h-[90vh] overflow-y-auto md:mt-0 md:ml-auto md:h-full md:max-h-full md:min-w-sm"
          :class="{ isTop: topSlidebar.id === slidebar.id }"
          @close="close(slidebar, $event)"
        />
      </div>
    </TransitionGroupSlideFromBottom>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import TransitionFadeIn from '@/components/transitions/TransitionFadeIn.vue'
import TransitionGroupSlideFromBottom from '@/components/transitions/TransitionGroupSlideFromBottom.vue'
import { Slidebar, SlidebarRecord } from '@/plugins/slidebar'

export default Vue.extend({
  components: {
    TransitionFadeIn,
    TransitionGroupSlideFromBottom
  },

  data() {
    return {
      sequence: 1 as number,
      slidebars: [] as SlidebarRecord[]
    }
  },

  computed: {
    topSlidebar(): SlidebarRecord | null {
      return this.slidebars.length ? this.slidebars[this.slidebars.length - 1] : null
    }
  },

  mounted() {
    document.body.appendChild(this.$el)
    document.addEventListener('keydown', this.closeByEscKey)

    this.$root.$on('Slidebar.open', this.open)
    this.$root.$on('Slidebar.close', this.close)
    this.$root.$on('Slidebar.closeTop', this.closeTop)
  },

  destroyed() {
    document.body.removeChild(this.$el)
    document.removeEventListener('keydown', this.closeByEscKey)

    this.$root.$off('Slidebar.open', this.open)
    this.$root.$off('Slidebar.close', this.close)
    this.$root.$off('Slidebar.closeTop', this.closeTop)
  },

  methods: {
    open(slidebar: Slidebar) {
      ;(document.activeElement as HTMLElement)?.blur()

      this.slidebars.push({
        ...slidebar,
        id: this.sequence++
      })
    },

    close(slidebar: SlidebarRecord, result: any = undefined) {
      if (!this.slidebars.find(({ id }) => id === slidebar.id)) {
        return
      }

      const closingRoutine = () => {
        this.slidebars = this.slidebars.filter(({ id }) => id !== slidebar.id)
        slidebar?.resolve(result)
      }

      const slidebarInstance = this._getSlidebarInstance(slidebar)

      if (slidebarInstance && 'before-close' in slidebarInstance.$listeners) {
        slidebarInstance.$emit('before-close', closingRoutine)
      } else {
        closingRoutine()
      }
    },

    closeTop() {
      if (this.topSlidebar) {
        this.close(this.topSlidebar)
      }
    },

    closeByEscKey(e: KeyboardEvent) {
      if (this.topSlidebar && e.key === 'Escape' && !e.defaultPrevented) {
        e.preventDefault()
        this.closeTop()
      }
    },

    closeByBackdropClick(e: MouseEvent, slidebar: SlidebarRecord) {
      if (e.target === e.currentTarget) {
        this.close(slidebar)
      }
    },

    _getSlidebarInstance(slidebar: SlidebarRecord) {
      return (this.$refs[`slidebar_${slidebar.id}`] as Vue[])[0].$children[0]
    }
  }
})
</script>

<style lang="postcss">
.SlidebarStack__slidebar {
  transition: filter 0.15s;

  &:not(.isTop) {
    filter: brightness(80%);
  }
}
</style>
