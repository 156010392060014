<template>
  <div>
    <div class="cursor-pointer py-1 px-2 text-sm text-blue-700 underline hover:text-blue-500" @click="isOpen = !isOpen">
      {{ $t('js.order_status.show_tracking') }}
    </div>
    <div v-if="isOpen" class="fixed inset-0 z-50 overflow-y-auto bg-black/40 px-4" @click="isOpen = !isOpen">
      <div class="my-32 mx-auto w-full max-w-md rounded bg-white" @click.stop>
        <div class="flex items-center p-4">
          <h1 v-if="!shipment.single_tracking_code" class="text-lg">
            {{ $t('js.order_status.no_codes') }}
          </h1>
          <div v-else class="mr-1 flex flex-col">
            <h1 class="mb-3 text-lg">
              {{ $t('views.orders.order_status_message.order_tracking') }}
            </h1>

            <div class="_tracking-link" v-html="shipment.single_tracking_code"></div>

            <div v-if="shipment.multiple_tracking_codes">
              {{ $t('views.orders.tracking_links.previous_tracking_numbers') }}
              <div class="_tracking-link" v-html="shipment.multiple_tracking_codes"></div>
            </div>
          </div>
          <button class="ml-auto text-2xl font-bold text-gray-400" @click="isOpen = !isOpen">×</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isOpen: false
    }
  }
})
</script>

<style lang="postcss" scoped>
._tracking-link :deep() a {
  @apply no-underline font-bold;
  &:hover {
    text-decoration: underline;
  }
}
</style>
