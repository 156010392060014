import Vue, { Component, PluginObject, PropOptions, VueConstructor } from 'vue'

import SlidebarStack from '@/components/slidebar/SlidebarStack.vue'

export type Slidebar = {
  component: Component
  props?: PropOptions
  resolve: (result: any) => never
}

export type SlidebarRecord = Slidebar & {
  id: number
}

export interface OpenSlidebarInterface extends Vue {
  (this: Vue): Promise<boolean>
}

declare module 'vue/types/vue' {
  interface Vue {
    $slidebar: OpenSlidebarInterface
  }
}

const slidebar: PluginObject<any> = {
  _container: undefined as Vue | undefined,

  install(Vue: VueConstructor) {
    const createModalStackContainer = (parent: Vue) => {
      const placeholder = document.createElement('div')
      document.body.appendChild(placeholder)

      return new Vue({
        parent,
        render: (h) => h(SlidebarStack)
      }).$mount(placeholder)
    }

    const getModalStackComponent = (parent: Vue) => {
      if (!this._container) {
        this._container = createModalStackContainer(parent)
      }

      return this._container.$children[0]
    }

    Vue.prototype.$slidebar = function (component: Component, props: object = {}) {
      return new Promise((resolve) =>
        getModalStackComponent(this.$root).open({
          component,
          props,
          resolve
        })
      )
    } as OpenSlidebarInterface
  }
}

export default slidebar
