<template>
  <div
    aria-hidden="true"
    :style="{
      'color': $color('cart.color.darkest')
    }"
  >
    <TransitionFadeIn>
      <div v-if="isOpen" class="fixed top-0 left-0 z-40 h-screen w-screen bg-black/30 pt-1.5" @click="close()"></div>
    </TransitionFadeIn>

    <TransitionSlideFromSide>
      <div
        v-if="isOpen"
        class="fixed inset-y-0 right-0 z-50 flex w-11/12 flex-col justify-between bg-white shadow-xl md:w-1/2 lg:w-1/3 2xl:w-1/4"
      >
        <div
          class="flex flex-1 items-center justify-between border-b py-2 px-6 text-lg md:py-4 md:px-8"
          :style="{
            'border-color': $color('cart.border.light')
          }"
        >
          <div
            class="flex items-center"
            :style="{
              'color': $color('base.button.primary.background')
            }"
          >
            <RIcon class="mr-2" name="check" />
            <!-- TODO: i18n -->
            Added to your cart
          </div>

          <div>
            <button
              class="text-3xl outline-none"
              :style="{
                'color': $color('cart.color.dark')
              }"
              @click="close()"
            >
              ×
            </button>
          </div>
        </div>

        <div v-if="items.length > 0" class="flex h-full w-full flex-col overflow-y-auto overscroll-contain px-4">
          <ProductItem
            v-for="(item, index) in digitalItems"
            :key="index"
            allow-removal
            class="mt-2 border-b pb-3"
            :item="item"
            :style="{
              'border-color': $color('cart.border.light')
            }"
          />
          <ProductItemsByShipDate editable-quantity :items="deliveryItems" />
        </div>

        <div
          v-else
          class="flex h-full items-center justify-center"
          :style="{
            'color': $color('cart.color.darkest')
          }"
        >
          {{ $t('js.shopping_cart_empty_state.title') }}
        </div>

        <div
          class="flex-1 border-t p-4"
          :style="{
            'border-color': $color('cart.border.light')
          }"
        >
          <div style="padding-bottom: calc(env(safe-area-inset-bottom, 1rem) - 1rem)">
            <RButton block variant="primary" @click="close()">
              {{ $t('js.shopping_cart.continue_shopping') }}
            </RButton>

            <RButton
              v-if="items.length > 0"
              block
              class="mt-4"
              :to="{ name: 'cart' }"
              variant="primary"
              @click.native="close()"
            >
              {{ $t('js.shopping_cart.proceed_to_cart') }}
            </RButton>
          </div>
        </div>
      </div>
    </TransitionSlideFromSide>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import ProductItem from '@/components/ProductItem.vue'
import ProductItemsByShipDate from '@/components/ProductItemsByShipDate.vue'
import TransitionFadeIn from '@/components/transitions/TransitionFadeIn.vue'
import TransitionSlideFromSide from '@/components/transitions/TransitionSlideFromSide.vue'
import RButton from '@/components/ui/RButton.vue'
import RIcon from '@/components/ui/RIcon.vue'
import { config } from '@/config'
import AnalyticsService from '@/services/AnalyticsService'

export default Vue.extend({
  components: {
    RButton,
    RIcon,
    ProductItemsByShipDate,
    ProductItem,
    TransitionFadeIn,
    TransitionSlideFromSide
  },

  data() {
    return {
      isProcessingPayment: false as boolean,
      hasBeenOpenBefore: false as boolean,
      isOpen: false as boolean
    }
  },

  computed: {
    ...mapGetters('cart', ['items', 'deliveryItems', 'digitalItems']),

    whitelabel() {
      return config.custom_domain
    }
  },

  watch: {
    $route() {
      this.close()
    }
  },

  mounted() {
    this.$root.$on('showCart', this.open)
  },

  beforeDestroy() {
    this.$root.$off('showCart', this.open)
  },

  methods: {
    ...mapActions('main', ['loadStore']),

    open() {
      this.isOpen = true

      if (!this.hasBeenOpenBefore) {
        this.openForTheFirstTime()
      }
    },

    openForTheFirstTime() {
      this.hasBeenOpenBefore = true

      if (config.slug) {
        this.loadStore({ slug: config.slug })
      }

      AnalyticsService.trackCheckout(this.items, { step: 1 })
    },

    close() {
      this.isOpen = false
    }
  }
})
</script>
