<template>
  <div class="_product_item">
    <DigitalProductStatus
      v-if="item.digital"
      :correction-notes="item.video_recording_correction_notes"
      :status="item.video_recording_status"
    />

    <div class="flex justify-between py-2 md:flex-row">
      <router-link class="_product_item_image mr-2 flex items-center" :to="itemUrl(item)">
        <img :alt="item.title" class="w-20 shrink-0 grow-0 bg-white" :src="item.square_image | imageUrl" />
      </router-link>

      <div class="flex max-w-sm grow flex-col self-center pl-2 md:flex-row md:pl-0">
        <div class="mt-0 flex flex-auto md:mt-0 md:ml-3 md:self-center md:text-base">
          <div class="w-full md:pr-2">
            <router-link
              class="py-1 text-sm font-semibold no-underline hover:text-blue-500 hover:underline"
              :style="{
                'color': $color('productItem.color.darkest')
              }"
              :to="itemUrl(item)"
            >
              {{ item.title }}
            </router-link>
            <div
              class="pt-1 text-sm"
              :style="{
                'color': $color('productItem.color.dark')
              }"
            >
              {{ item.style_name }}
            </div>
            <div
              class="pt-1 text-sm"
              :style="{
                'color': $color('productItem.color.darkest')
              }"
            >
              <span v-if="editable">{{ itemPrice | formatCurrency }} -</span>
              {{ item.variant_name }} - {{ item.size | humanizeSize }}
            </div>
          </div>
        </div>

        <div v-if="editableQuantity" class="mt-3 flex w-full items-center self-center md:mt-0 md:w-auto md:flex-col">
          <div v-if="!item.upsell" class="_quantity">
            <RButton variant="light" @click="decreaseQuantity()">-</RButton>
            <input
              class="r-input"
              maxlength="3"
              type="number"
              :value="item.quantity"
              @change="setQuantity(parseInt($event.target.value))"
            />
            <RButton :disabled="!canIncreaseQuantity()" variant="light" @click="increaseQuantity()">+</RButton>
          </div>
          <div class="flex w-full justify-end md:mt-2 md:mr-0 md:justify-center">
            <a
              class="_remove_item hover:underline md:pt-2"
              href="javascript:;"
              :style="{
                'color': $color('productItem.color.darker')
              }"
              @click="removeItem()"
            >
              {{ $t('js.order_items_list.remove_item') }}
            </a>
          </div>
        </div>
      </div>

      <div v-if="editableSize" class="shrink self-center">
        <ProductSizePicker
          v-if="sizes?.length > 0"
          :items="items"
          :sizes="sizes"
          :stock="item.stock"
          :value="selectedSize"
          @input="updateSize"
        />
      </div>

      <div
        v-if="!editable"
        class="flex flex-col self-center text-center md:shrink"
        :class="{ 'md:mr-4': allowRemoval }"
      >
        <div>{{ item.quantity }} × {{ itemPrice | formatCurrency }}</div>

        <div v-if="!editableQuantity && allowRemoval" class="flex w-full justify-end md:mt-2 md:mr-0 md:justify-center">
          <a
            class="_remove_item hover:underline md:pt-2"
            href="javascript:;"
            :style="{
              'color': $color('productItem.color.darker')
            }"
            @click="removeItem()"
          >
            {{ $t('js.order_items_list.remove_item') }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="invalidEuropeanSize" class="text-sm text-yellow-600">
      Sizes available in Europe: {{ item.eu_sizes | humanize }}
    </div>

    <DigitalProduct
      v-if="item.video_recording_request"
      class="flex pb-2 md:flex-row"
      :editable="editableDigitalProduct"
      :video-recording-request="item.video_recording_request"
      :video-recording-title="item.title"
      :video-recording-url="item.video_recording_url"
      @input="updateDigitalProduct"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import DigitalProductStatus from '@/components/order/DigitalProductStatus.vue'
import DigitalProduct from '@/components/product/DigitalProduct.vue'
import ProductSizePicker from '@/components/ProductSizePicker.vue'
import RButton from '@/components/ui/RButton.vue'
import itemUrlMixin from '@/mixins/itemUrlMixin'
import PricingService from '@/services/PricingService'
import SizingService from '@/services/SizingService'
import StockService from '@/services/StockService'
import { formatCurrency } from '@/utils/formatCurrency'
import { humanizeSize } from '@/utils/humanizeSize'
import { imageUrl } from '@/utils/imageUrl'

export default Vue.extend({
  components: {
    DigitalProduct,
    DigitalProductStatus,
    ProductSizePicker,
    RButton
  },

  filters: {
    formatCurrency,
    humanizeSize,
    imageUrl,
    humanize(sizes) {
      return sizes.join(', ')
    }
  },

  mixins: [itemUrlMixin],

  props: {
    item: {
      type: Object,
      required: true
    },
    editableQuantity: Boolean,
    editableSize: Boolean,
    editableVariant: Boolean,
    editableDigitalProduct: Boolean,
    allowRemoval: Boolean
  },

  data() {
    return {
      sizes: {},
      europeanVisitor: false
    }
  },

  computed: {
    ...mapGetters('cart', ['items']),

    editable() {
      return this.editableQuantity || this.editableSize || this.editableVariant
    },

    selectedSize() {
      return this.sizes.find((size) => size.code === this.item.size)
    },

    itemPrice(): number {
      return PricingService.effectivePrice(this.item.price, this.item.promotion)
    },

    invalidEuropeanSize() {
      return this.europeanVisitor && !SizingService.validEuropeanSize(this.item) && this.item?.european_shipping
    }
  },

  mounted() {
    this.sizes = this.item.sizes
    this.fetchEuropeanVisitor()
  },

  methods: {
    ...mapActions('cart', ['isEuropeanVisitor']),

    canIncreaseQuantity() {
      return StockService.sizeAvailable(this.item.size, this.item.stock, this.items)
    },

    increaseQuantity() {
      this.setQuantity(this.item.quantity + 1)
    },

    decreaseQuantity() {
      this.setQuantity(Math.max(this.item.quantity - 1, 0))
    },

    setQuantity(quantity) {
      if (this.item.stock) {
        let itemsLeftInStock = StockService.sizeLeftInStock(this.item.size, this.item.stock, this.items)
        quantity = Math.min(quantity, itemsLeftInStock + this.item.quantity)
      }

      this.$store.dispatch('cart/setQuantity', {
        item: this.item,
        quantity: isNaN(quantity) || quantity < 0 ? 1 : quantity
      })
      this.$forceUpdate()
    },

    removeItem() {
      this.$store.dispatch('cart/setQuantity', { item: this.item, quantity: 0 })

      if (this.onlyUpsell()) {
        this.$store.dispatch('cart/removeUpsell')
      }
    },

    updateSize(size) {
      let params = {
        ...this.item,
        size: size
      }
      this.$emit('orderItemUpdated', params)
    },

    updateDigitalProduct(video_recording_request) {
      let params = {
        ...this.item,
        video_recording_request: video_recording_request
      }
      this.$emit('orderItemUpdated', params)
    },

    onlyUpsell() {
      return this.items.every((item) => item.upsell)
    },

    async fetchEuropeanVisitor() {
      this.europeanVisitor = await this.isEuropeanVisitor()
    }
  }
})
</script>

<style lang="postcss" scoped>
._quantity {
  @apply flex cursor-pointer;
  min-width: 110px;
  max-width: 110px;

  button {
    @apply p-2 text-center border outline-none;
    &::-moz-selection {
      background: none;
    }
    &::selection {
      background: none;
    }
    flex: 1 1 50px;

    &.disabled {
      filter: brightness(105%);
      color: var(--r-input-disabled-text-color);
    }
  }
  input {
    @apply text-center border outline-none;
    width: 54px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

._remove_item {
  @apply text-center no-underline text-xs cursor-pointer;
}

._remove_item_mobile {
  @apply block self-center mr-2 text-lg;
}

._product_item {
  @apply justify-between py-2;
}

._product_item_image {
  min-width: 5rem; /* Based on w-20 added to <img> */
}
</style>
