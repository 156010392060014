<template>
  <div class="flex flex-col">
    <div class="my-2 flex items-center">
      <p class="font-semibold">
        {{ $t('views.orders.digital_product.recording_status.title') }}
      </p>
      <Tooltip class="ml-auto w-1/2 md:w-1/4" size="extra-large" :text="statusTooltipText">
        <div
          class="rounded-full py-1 px-2 text-center text-sm font-bold uppercase text-white"
          :class="{
            'bg-yellow-400': status === 'pending' || status === 'approved' || status === 'recorded',
            'bg-green-500': status === 'completed',
            'bg-gray-400': status === 'canceled' || status === 'rejected',
            'bg-red-500': status === 'correction_needed'
          }"
        >
          {{ statusLabel }}
        </div>
      </Tooltip>
    </div>
    <div v-if="correctionNotes">
      <div :style="{ 'color': $color('checkoutItem.color.dark') }">
        <p
          class="block w-full overflow-auto whitespace-normal rounded border border-red-600 p-2 text-sm font-bold subpixel-antialiased shadow-none outline-none"
        >
          <q>
            <i>{{ correctionNotes }}</i>
          </q>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import Tooltip from '@/components/Tooltip.vue'

export default Vue.extend({
  components: {
    Tooltip
  },

  props: {
    status: {
      type: String,
      required: true
    },

    correctionNotes: {
      type: String,
      default: undefined
    }
  },

  computed: {
    statusTooltipText() {
      if (this.status === 'pending' || this.status === 'approved' || this.status === 'recorded') {
        return this.$t('views.orders.digital_product.recording_status.pending_tooltip')
      }

      if (this.status === 'completed') {
        return this.$t('views.orders.digital_product.recording_status.completed_tooltip')
      }

      if (this.status === 'canceled' || this.status === 'rejected') {
        return this.$t('views.orders.digital_product.recording_status.canceled_tooltip')
      }

      if (this.status === 'correction_needed') {
        return this.$t('views.orders.digital_product.recording_status.correction_needed_tooltip')
      }

      return ''
    },

    statusLabel() {
      if (this.status === 'pending' || this.status === 'approved' || this.status === 'recorded') {
        return this.$t('views.orders.digital_product.recording_status.pending')
      }

      if (this.status === 'completed') {
        return this.$t('views.orders.digital_product.recording_status.completed')
      }

      if (this.status === 'canceled' || this.status === 'rejected') {
        return this.$t('views.orders.digital_product.recording_status.canceled')
      }

      if (this.status === 'correction_needed') {
        return this.$t('views.orders.digital_product.recording_status.correction_needed')
      }

      return ''
    }
  }
})
</script>
