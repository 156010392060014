export default {
  methods: {
    itemUrl(item) {
      return '/' + item.product_slug + this.itemVariantUrlParam(item)
    },
    itemVariantUrlParam(item) {
      return item.product_variant_id ? '?variant=' + item.product_variant_id : ''
    }
  }
}
