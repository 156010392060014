import '@/assets/css/main.css'
// Global logging of errors to Bugsnag
import '@/services/BugsnagService'

import { StripePlugin } from '@vue-stripe/vue-stripe'
import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueLastModal from 'vue-last-modal'
import VueObserveVisibility from 'vue-observe-visibility'

import { config } from '@/config'
import App from '@/packs/main/App.vue'
import router from '@/packs/main/router'
import store from '@/packs/main/store'
import abTests from '@/plugins/abTests'
import breakpoints from '@/plugins/breakpoints'
import colors from '@/plugins/colors'
import featureFlags from '@/plugins/featureFlags'
import { i18n } from '@/plugins/i18n'
import slidebar from '@/plugins/slidebar'
import snackbar from '@/plugins/snackbar'
import loadFonts from '@/utils/loadFonts'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['klarna-placement', /sl-\w*/]

// vue plugins
Vue.use(breakpoints)
Vue.use(abTests)
Vue.use(featureFlags)
Vue.use(colors)
Vue.use(slidebar)
Vue.use(VueLastModal, {
  buttonClassName: 'r-button r-button--secondary',
  primaryButtonClassName: 'r-button r-button--primary'
})
Vue.use(snackbar)

// intersection observer
Vue.use(VueObserveVisibility)

// Stripe
Vue.use(StripePlugin, { pk: config.stripe_publishable_key })

// Metatags
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Setup Google Analytics 4
Vue.use(
  VueGtag,
  {
    config: {
      id: config.google_analytics_4_id,
      params: {
        // Enable to see events in GA's Debug View
        // debug_mode: true,
        send_page_view: true
      }
    }
  },
  router
)

// Load website fonts
loadFonts()

const app = new Vue({
  i18n,
  store,
  router,
  created: () => store.dispatch('init'),
  render: (h) => h(App)
}).$mount('#app')

// Expose app in E2E tests
if (window.hasOwnProperty('Cypress')) {
  ;(window as any).app = app
}
