<template>
  <div class="min-h-screen antialiased text-black">
    <SitewideMessages />
    <ShoppingCart />

    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'

import ShoppingCart from '@/components/base/ShoppingCart.vue'
import SitewideMessages from '@/components/SitewideMessages.vue'
import { config } from '@/config'
import { loadFbPixel, loadGtm, loadKlarna, loadOneTrust } from '@/utils/scriptLoader'

export default Vue.extend({
  components: {
    ShoppingCart,
    SitewideMessages
  },

  created() {
    if (!config.custom_domain) loadOneTrust()
    loadGtm()
    loadFbPixel()
    loadKlarna()
  },

  metaInfo(): MetaInfo {
    return {
      title: 'Official Merchandise',
      titleTemplate: config.custom_domain ? '%s' : '%s | Represent'
    }
  }
})
</script>
