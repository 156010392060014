<template>
  <div
    class="_sitewide_message z-50 md:px-32"
    :class="{
      'bg-red-100 text-red-800': level === 'error',
      'bg-yellow-200 text-yellow-900': level === 'warn',
      'bg-blue-100 text-blue-800': level === 'info'
    }"
    :style="{
      'border-color': $color('base.border.lightest')
    }"
  >
    <div class="relative mx-auto max-w-4xl pr-3 text-center">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    'text': {
      type: String,
      default: ''
    },
    'level': {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="postcss" scoped>
._sitewide_message {
  @apply sticky top-0 px-4 py-4 w-full h-13 bg-white border-b shadow-bottom;
}
</style>
